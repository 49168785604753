<template>
    <div class="confirm-popup" :class="{ show: showPopup }">
        <div class="confirm-popup__bg" @click="hide()"></div>
        <div class="confirm-popup__wrapper">
            <div class="confirm-popup__content">
                <div v-if="hide" class="confirm-popup__content-close" @click="hide()">
                    <img src="@/assets/img/topup-close.svg" alt="" />
                </div>
                <div class="confirm-popup__content-wrapper">
                    <div class="confirm-popup__content-info">
                        <p v-if="title" class="title" v-html="title"></p>
                        <p v-if="description" class="desc">
                            {{ description }}
                        </p>
                    </div>
                    <slot name="content"></slot>
                    <div class="confirm-popup__content-actions">
                        <slot name="actions"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
    props: {
        title: {
            type: String,
            default: "",
        },
        description: {
            type: String,
            default: "",
        },
        showPopup: {
            type: Boolean,
            default: false,
        },
        hide: {
            type: Function,
            required: false,
        },
    },
})
export default class InformationPopup extends Vue {
    public showPopup!: boolean;

    Hide() {
        this.showPopup = false;
    }
}
</script>
