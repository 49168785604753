<template>
    <div>
        <div class="page-header" :class="{ show: visibleHeader }">
            <header v-if="phone !== ''" class="header">
                <div class="header-wrapper">
                    <div class="header-logo">
                        <router-link to="/"><img src="@/assets/img/logo.svg" alt="" /></router-link>
                    </div>
                    <div class="header-back">
                        <div class="header-back__btn" @click="Hide()">
                            <img src="@/assets/img/back-btn.svg" alt="" />
                        </div>
                    </div>
                    <div class="header-number">
                        <span class="header-number-text">{{ $t("header.phone") }}</span>
                        <span class="header-number-num">{{ phone }}</span>
                    </div>
                    <div class="header-menu">
                        <nav class="header-menu__wrapper">
                            <router-link to="/" class="header-menu__link" active-class="header-menu__link-active">{{ $t("header.home") }}</router-link>
                            <router-link to="/offers" class="header-menu__link" active-class="header-menu__link-active">{{ $t("header.offers") }}</router-link>
                            <router-link to="/options" class="header-menu__link" active-class="header-menu__link-active">{{ $t("header.service") }}</router-link>
                            <router-link v-if="visibleRefer" to="/refer" class="header-menu__link" active-class="header-menu__link-active">{{ $t("header.refer") }}</router-link>
                            <router-link to="/history" class="header-menu__link" active-class="header-menu__link-active">{{ $t("header.history") }}</router-link>
                            <!--                            <router-link to="/recharge" class="header-menu__link" active-class="header-menu__link-active">{{ $t("header.recurrent") }}</router-link>-->
                        </nav>
                        <nav class="header-menu__submenu">
                            <router-link to="/notification" class="header-menu__sublink" active-class="header-menu__sublink-active">{{ $t("header.notification") }} <span v-if="isHaveNotifications" class="header-menu__dott" /></router-link>
                            <router-link to="/support" class="header-menu__sublink" active-class="header-menu__sublink-active">{{ $t("header.support") }}</router-link>
                            <router-link to="/setting" class="header-menu__sublink" active-class="header-menu__sublink-active">{{ $t("header.setting") }}</router-link>
                            <router-link to="/setting/privacy" class="header-menu__sublink" active-class="header-menu__sublink-active">{{ $t("header.terms") }}</router-link>
                        </nav>
                        <div class="header-menu__cancel">
                            <a :href="env().VUE_APP_SITE_URL" class="cancel-btn">
                                <span class="icon left"><img src="@/assets/img/cancel-btn-left.svg" alt="" /></span>
                                <span class="text">{{ $t("header.toSite") }}</span>
                            </a>
                        </div>
                    </div>
                    <div class="header-actions">
                        <div class="header-actions__wrapper">
                            <router-link to="/topup" class="header-actions__btn btn-orange">
                                <span class="text">{{ $t("header.topup") }}</span>
                                <span class="icon"><img src="@/assets/img/plus.svg" alt="" /></span>
                            </router-link>
                            <a class="header-actions__btn" @click="ShowLogOutPopup = true">
                                <span class="text">{{ $t("header.logOut") }}</span>
                                <span class="icon"><img src="@/assets/img/logout.svg" alt="" /></span>
                            </a>
                        </div>
                    </div>
                </div>
            </header>
        </div>
        <InformationPopup :show-popup="ShowLogOutPopup" :title="$t('header.logOutText')" :hide="HideLogOutPopup">
            <template #actions>
                <InformationActionsBtn :name="$t('header.yes')" @click="Logout" />
                <InformationActionsBtn :name="$t('header.no')" @click="ShowLogOutPopup = false" />
            </template>
        </InformationPopup>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import ApiApp from "@/api/ApiApp";
import InformationPopup from "@/components/InformationPopup/InformationPopup.vue";
import InformationActionsBtn from "@/components/InformationPopup/InformationActionsBtn.vue";
@Options({
    components: {
        InformationPopup,
        InformationActionsBtn,
    },
    watch: {
        $route(to: any, from: any) {
            this.visibleHeader = false;
        },
    },
})
export default class Header extends Vue {
    phone = "";

    public ShowLogOutPopup = false;
    visibleHeader = false;
    visibleRefer = false;
    isHaveNotifications = false;

    env() {
        return process.env;
    }

    created() {
        (window as any).header = this;
        let phone = localStorage.getItem("phone");

        if (phone) {
            const formattedPhone = phone.split("");
            formattedPhone.splice(2, 0, " ");
            this.phone = "+" + formattedPhone.join("");
        }

        this.GetExternalConfig();
        this.CheckNotification();
    }

    private async GetExternalConfig(): Promise<void> {
        const response = await ApiApp.GetExternalConfig();

        if (response && response?.data?.mgmSettings?.isActive) {
            this.visibleRefer = true;
        }
    }

    private async CheckNotification(): Promise<void> {
        const response = await ApiApp.CheckNotification();

        if (response && response.data) this.isHaveNotifications = true;
    }

    Show() {
        this.visibleHeader = true;
    }
    HideLogOutPopup() {
        this.ShowLogOutPopup = false;
    }

    Hide() {
        this.visibleHeader = false;
    }

    private async Logout(): Promise<void> {
        await ApiApp.LogOut();
        localStorage.removeItem("phone");
        document.cookie.split(";").forEach(function (c) {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        this.$router.replace("/login");
        console.log("To Login --> Logout");
    }
}
</script>
