<template>
    <div class="confirm-popup__content-actions__btn">
        <span>{{ name }}</span>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
    props: {
        name: {
            type: String,
            default: "",
        },
    },
})
export default class InformationActionsBtn extends Vue {}
</script>

<style scoped></style>
