<template>
    <div class="preloader" :class="{ show: visible }">
      <img src="@/assets/img/logo.svg" class="preloader-animation" alt="" />
    </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class Preloader extends Vue {
    visible = false;

    created() {
        (window as any).Preloader = this;
    }

    Show() {
        this.visible = true;
    }

    Hide() {
        this.visible = false;
    }
}
</script>

<style scoped lang="less">
.preloader.show {
    opacity: 1 !important;
    visibility: visible !important;
    transition: all 0s !important;
}
.preloader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ebebeb;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s;
    &-animation {
        width: 96px;
        @media (max-width: 768px) {
            width: 80px;
        }
    }
}
</style>
