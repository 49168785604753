<template>
    <div>
        <slot />
    </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class Auth extends Vue {}
</script>

<style scoped></style>
