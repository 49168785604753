<template>
    <component :is="layout">
        <router-view />
        <Preloader />
        <Notification />
    </component>
</template>

<script lang="ts">
import "@/styles/index.less";
import { Vue, Options } from "vue-class-component";
import Preloader from "@/components/Preloader.vue";
import Notification from "@/components/Notification.vue";
import axios from "axios";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/it";

@Options({
    components: {
        Preloader,
        Notification,
    }
})
export default class App extends Vue {
    get layout() {
        return this.$route.meta.layout || "default-layout";
    }

    created() {
        this.checkLang();
        axios.interceptors.response.use(
            config => {
                if (config.request.responseURL.includes("CookieExpired")) {
                    localStorage.removeItem("phone");
                    document.cookie.split(";").forEach(function (c) {
                        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
                    });
                    localStorage.setItem('CookieExpired', new Date().toDateString());
                    window.dispatchEvent(new CustomEvent('CookieExpired-localstorage-changed', {
                      detail: {
                        storage: localStorage.getItem('CookieExpired')
                      }
                    }));
                } else {
                  const exp = localStorage.getItem('CookieExpired')
                  if (exp) {
                    localStorage.removeItem('CookieExpired')
                    window.dispatchEvent(new CustomEvent('CookieExpired-localstorage-changed', {
                      detail: {
                        storage: localStorage.getItem('CookieExpired')
                      }
                    }));
                  }
                }
                return config;
            },
            error => {
                // Do something with request error
                return Promise.reject(error);
            }
        );
    }

    mounted(){
      window.addEventListener('CookieExpired-localstorage-changed', (event: any) => {
        if (typeof event.detail.storage === 'string') {
          console.log(this.$route)
          if (!this.$route.meta.noAuth && this.$route.name !== undefined) {
            console.log('LocalCookie to login')
            return this.$router.push({ name: "Login" });
          }
        }
      });
    }
  

    checkLang() {
        let lang = localStorage.getItem("lang");
        if (lang) {
            switch (lang) {
                case "en":
                    localStorage.setItem("lang", "en");
                    this.$i18n.locale = "en";
                    dayjs.locale("en");
                    break;
                case "it":
                    localStorage.setItem("lang", "it");
                    this.$i18n.locale = "it";
                    dayjs.locale("it");
                    break;
            }
        } else {
            localStorage.setItem("lang", "it");
            this.$i18n.locale = "it";
            dayjs.locale("it");
        }
    }
}
</script>
<style lang="less">
@import "/node_modules/swiper/swiper.less";
@import "/node_modules/swiper/modules/effect-fade/effect-fade.less";
</style>
