<template>
    <div class="main-header">
        <div class="main-header__wrapper">
            <div class="main-header__actions">
                <div class="main-header__item" @click="showHeader()">
                    <img src="@/assets/img/mobile-menu.svg" alt="" />
                </div>
            </div>
            <div class="main-header__logo">
                <img src="@/assets/img/mobile-header.svg" alt="" />
            </div>
            <div class="main-header__actions">
                <div class="main-header__lang" @click="SwitchLang()">
                    <span>{{ $t("main.langMobile") }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class MobileHeader extends Vue {
    showHeader() {
        (window as any).header.Show();
    }

    SwitchLang() {
        let lang = localStorage.getItem("lang");
        if (lang) {
            switch (lang) {
                case "en":
                    localStorage.setItem("lang", "it");
                    this.$i18n.locale = "it";
                    break;
                case "it":
                    localStorage.setItem("lang", "en");
                    this.$i18n.locale = "en";
                    break;
            }
        }
    }
}
</script>

<style scoped></style>
