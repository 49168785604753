<template>
    <div id="hit" class="hit-message">
        <div
            class="hit-wrapper"
            :class="{
                show: showNotification,
                danger: typeNotification === 'danger',
                success: typeNotification === 'success',
            }"
        >
            <div class="hit hit-message-text">
                {{ textNotification }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class Notification extends Vue {
    showNotification = false;
    textNotification = "";
    typeNotification: "success" | "danger" = "danger";

    created() {
        (window as any).Notification = this;
    }

    show(text: any, type: "success" | "danger" = "danger", delay = 1000) {
        this.showNotification = true;
        this.textNotification = text;
        this.typeNotification = type;
        setTimeout(() => {
            this.showNotification = false;
        }, delay);
    }
}
</script>

<style scoped lang="less">
.hit-message {
    .hit-wrapper.show {
        top: 0;
    }
    .hit-wrapper.success {
        background: transparent;
        min-height: auto;
        .hit {
            background: #00ffd1;
            min-height: auto;
        }
    }
    .hit-wrapper {
        position: fixed;
        top: -100px;
        right: 21px;
        max-width: 400px;
        width: 100%;
        z-index: 10000;
        transition: 0.4s;
        .hit {
            background: #e85217;
            width: calc(100% - 24px * 2);
            padding: 24px;
            font-family: "GT Ultra";
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 130%;
            text-align: center;
            color: #23262c;
        }
    }
}
</style>
