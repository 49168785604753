import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/topup",
        name: "Topup",
        component: () => import("@/views/Topup/Topup.vue"),
        meta: {
            layout: "topup-layout",
            title: "Top up | WSC",
        },
    },
    {
        path: "/topup/payment",
        name: "TopupPayment",
        component: () => import("@/views/Topup/TopupPayment.vue"),
        meta: {
            layout: "topup-layout",
            title: "Top up | WSC",
        },
    },
    {
        path: "/topup/success",
        name: "TopupSuccess",
        component: () => import("@/views/Topup/TopupSuccess.vue"),
        meta: {
            layout: "topup-layout",
            title: "Top up | WSC",
            noAuth: true
        },
    },
    {
        path: "/topup/failed",
        name: "TopupFailed",
        component: () => import("@/views/Topup/TopupFailed.vue"),
        meta: {
            layout: "topup-layout",
            title: "Top up | WSC",
            noAuth: true
        },
    },
];

export default routes;
